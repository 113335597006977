import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Grid from '@mui/material//Grid'
import Typography from '@mui/material/Typography'
import Layout from '../components/layout'
import SEO from '../components/seo'


const PostPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Posts" />
      <Grid container direction='column' spacing={4}>
        {data.allMdx.nodes.map(post => (
          <Grid item container key={post.id} spacing={4}>
            <Grid item xs={3} sx={{ marginTop: '0.25rem'}}>
              <Typography>{post.frontmatter.date}</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography 
                component={Link} 
                to={post.slug} 
                sx={{ fontSize: '1.4rem' }}
              >
                {post.frontmatter.title}
              </Typography>
              <Typography>{post.frontmatter.description}</Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Layout>
  )
}

export default PostPage

export const query = graphql`
  query BlogPostQuery {
    allMdx(
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {frontmatter: {publish: {eq: true}}}
    ) {
      nodes {
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          description
          title
        }
        slug
        id
      }
    }
  }
`